//! Timonwa font CSS Reset
//! Default h1-h6 and p styling and body font-size (Start)
//! SMALL SCREEN FIRST RWD
// the ffg queries determine the font size, line height and margin bottom of the elements in the file

//* small screen of 0px to 608px
// body
body {
  @apply text-mainColor;
  font-size: 16px !important;
}
// text elements
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 130% !important;
}
p {
  line-height: 130% !important;
  margin-bottom: 0.5rem;
}
h1 {
  font-size: 2.125rem;
  font-weight: 700;
  margin-bottom: 0.8rem;
}
h2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 0.75rem;
}
h3 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.6rem;
}
h4 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
h5 {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
h6 {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0.3rem;
}

//* large screen width of 960px to 1024px
@media screen and (min-width: 960px) {
  // body
  body {
    font-size: 17px;
  }
  // text elements
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    line-height: 140%;
  }
}
//! Default h1-h6 and p styling and body font-size (End) /
