.isLoading {
  @apply grid justify-center items-center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.5);
  z-index: 20000;
  cursor: pointer;
}
