//! react swiper overrides
.swiper {
  z-index: 0;
  // slides
  .swiper-slide {
    z-index: 0;
    width: initial;
    height: initial;
  }
  // paginations
  .swiper-pagination-bullet {
    z-index: 0;
    @apply bg-orangeLight;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    @apply bg-tramangoOrange;
  }
  // prev and next btns
  .swiper-button-prev,
  .swiper-button-next {
    @apply text-tramangoOrange;
    width: 60px;
    min-width: 60px;
  }
  .swiper-button-prev::after {
    font-size: 1rem;
  }
  .swiper-button-next::after {
    font-size: 1rem;
  }
  .swiper-button-prev:hover,
  .swiper-button-next:hover {
    @apply text-tramangoOrange;
  }
}

//! React Dates overrides
//* date picker
.flightEngine,
.flightEngineMini,
.stays,
.experience {
  z-index: 10;
  .SingleDatePicker,
  .SingleDatePicker > div,
  .SingleDatePickerInput,
  .DateInput {
    width: 100%;
    height: 100%;
    border: transparent;
    border-radius: 0px;
    appearance: none;
    background-color: transparent;
  }

  .DateRangePickerInput {
    border: none;
    display: flex;
    align-items: center;
  }

  .DateInput {
    height: 100%;
    margin: auto;
  }

  .DateInput_input__focused {
    border: none !important;
    appearance: none;
    background-color: transparent;
  }

  // the calenders
  .SingleDatePicker_picker,
  .SingleDatePicker_picker_1,
  .SingleDatePicker_picker__directionLeft,
  .SingleDatePicker_picker__directionLeft_2 {
    z-index: 2;
    top: 55px !important;
    left: 0 !important;
    // right: 0 !important;

  }

  .DateRangePicker_picker,
  .DateRangePicker_picker_1.DateRangePicker_picker__directionLeft.DateRangePicker_picker__directionLeft_2 {
    // top: 60px !important;
    // left: initial !important;
    // right: 0;
    z-index: 2;
  }
  .DayPickerNavigation_button__verticalDefault {
    align-items: center ;
    justify-content: center ;
    display: inline-flex !important;
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    @apply bg-tramangoOrange;
    border: none;
  }
  .CalendarDay__selected_span,
  .CalendarDay__selected_span:hover {
    @apply bg-orangeLight text-mainColor border-orangeLight;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    @apply bg-orangeLight text-mainColor border-orangeLight;
  }
  .CalendarDay__default:hover {
    @apply bg-orangeLight text-mainColor border-orangeLight;
    /* Add any other hover styles you want */
  }
}

//! range slider overrides
//* multi range slider
.mrs-container {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .slider {
    position: relative;
    min-width: 260px;
    width: 100%;
  }

  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }

  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
  }

  .slider__track {
    background-color: #ffece2;
    width: 100%;
    z-index: 1;
  }

  .slider__range {
    background-color: #ff5e0de9;
    z-index: 2;
  }

  .slider__left-value,
  .slider__right-value {
    color: currentColor;
    margin-top: 20px;
    bottom: 10px;
  }

  .slider__right-value {
    right: 0px;
  }

  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    min-width: 260px;
    width: 100%;
    outline: none;
  }

  .thumb--zindex-3 {
    z-index: 3;
  }

  .thumb--zindex-4 {
    z-index: 4;
  }

  .thumb--zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #ff5e0d;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ff5e0d;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #ff5e0d;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ff5e0d;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
}
