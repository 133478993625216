//! tailwind config (Start)
@tailwind base;
@tailwind components;
@tailwind utilities;
//! tailwind config (End)

//! fonts imported (Start)
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,500;0,700;1,400;1,500;1,700&display=swap");
//! fonts imported (End)

//! global styles (Start)
//* all contents
* {
  box-sizing: border-box;
}

//* to smoothly animate the scroll from one place to another place
@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

//* to ensure that when you click on an id link, the header will not cover the beginning of the content the id is pointing to
[id] {
  scroll-margin-top: 5rem;
}

//* body
html,
body {
  margin: 0;
  padding: 0;
  left: 0;
  right: 0;
  // font-family: "Lato";
  font-family: "DM Sans", sans-serif;
  @apply text-mainColor;
  background-color: #fafafa;
}

//* scrollbar
// width
::-webkit-scrollbar {
  width: 10px;
  height: 7px;
}

// Track
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

// Handle
::-webkit-scrollbar-thumb {
  @apply bg-tramangoOrange;
}

// Handle on hover
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//* wrapper that holds the left view that shows the side bar and the right view that shows the page contents
.wrapper {
  margin: 0;
  padding: 0;

  .bottom {
    display: flex;
  }
  // the left side of the layout that holds the side bar
  .left {
    @apply flex flex-col shadow-md bg-white;
    overflow-x: hidden;
    padding: 0;

    height: calc(
      100vh - 60px
    ); // 100vh - height of the navbar You can find the nave height in NavBar.module.scss or navbar css folder
    margin: 0;
    width: 100px;
    position: fixed;
    transition: 0.15s all ease-in;
  }

  // the right side of the layout that displays all the pages
  .right {
    @apply w-full h-full;
    overflow-x: hidden;
    margin-left: 100px;
    transition: 0.15s all ease-in;
    width: calc(100% - 100px);
  }

  // container that wraps the content for each pages
  .container {
    padding-top: 50px;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    background-color: #fafafa;
  }

  // all the sections inside a container
  .section {
    width: 100%;
    max-width: 100%;
    padding: 0 50px;
    margin-bottom: 60px;
    &:last-child {
      margin-bottom: 100px;
    }
  }
  .mapForOneAddress {
    height: 300px;
    width: inherit;
    margin-bottom: 30px;
    border-radius: 20px;
  }
  // .longmap {
  //   width: 1000px;
  //   height: 300px;
  //   border-radius: 20px;
  // }
}
@media (max-width: 768px) {
  .wrapper {
    .left {
      width: 0px;
    }
    .right {
      margin-left: 0;
      width: 100%;
    }

    .section {
      padding: 0 20px;
      margin-bottom: 40px;
    }
  }
}
@media (max-width: 500px) {
  .wrapper {
    .container {
      padding-top: 30px;
    }
    .section {
      margin-bottom: 30px;
    }
  }
}

//* input stylings
// remove outline when the user is inputting details
input:focus,
select:focus {
  outline: none;
}
 //checkbox
// input[type="checkbox"] {
//   transform: scale(1);
//   width: unset;
//   height: unset;
//   border: 1px solid #ff5e0d;
//   box-sizing: border-box;
//   border-radius: 5px;
//   margin-right: 10px;
//   margin-top: 1px;
//   line-height: 5px;
//   filter: hue-rotate(157deg);
// }

/* Hide the default checkbox */
input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  outline: none; /* Remove outline */
}

/* Create a custom checkbox */
input[type="checkbox"]::before {
  content: ""; /* Create the pseudo-element */
  display: block;
  width: 100%;
  height: 100%;
  border: 1px solid #ff5e0d;
  border-radius: 5px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
}

/* Style the checkmark */
input[type="checkbox"]:checked::before {
  content: "\2713"; /* Unicode checkmark symbol */
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 18px; /* Adjust according to checkbox height */
  color: #fff; /* Color of the checkmark */
  background-color: #ff5e0d; /* Background color when checked */
}


// radio
input[type="radio"] {
  transform: scale(1);
  width: unset;
  height: unset;
  border: 1px solid #ff5e0d;
  box-sizing: border-box;
  border-radius: 5px;
  margin-right: 10px;
  margin-top: 1px;
  line-height: 5px;
  filter: hue-rotate(157deg);
}
// date input icon
::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23cecece" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>');
}

//* canceling tailwind image config
img {
  max-width: unset;
}
.shhh {
  user-select: none; /* Prevents text selection */
  -webkit-touch-callout: none; /* Prevents long press action on mobile */
  -webkit-user-select: none; /* Older versions of Safari */
  -khtml-user-select: none; /* Older versions of Konqueror */
  -moz-user-select: none; /* Older versions of Firefox */
  -ms-user-select: none; /* Older versions of Internet Explorer/Edge */
}
// Client-Only.js styling for pre-mounted content
.viewport-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  background-color: rgba(0, 0, 0, 0.1);
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

//Datepicker for ranges
.DateRangePickerInput_arrow {
  z-index: 1;
  margin-left: -2vw;
  margin-right: 1vw;

  @media screen and (max-width: 768px) {
    margin-left: -10vw;
    margin-right: 4vw;
  }
}

.DateRangePicker {
  width: 100%;
}

.flightEngine .DateRangePicker_picker_1 {
  z-index: 2;
}

.PhoneInputCountryIconUnicode {
  font-size: 22.5px;
}

//! global styles (End)
.promocodemobile {
  margin-bottom: 30px !important;
}

.grow-left {
  flex: 1;
}

/* Custom utility class for the first box shadow */
.box-shadow-x {
  box-shadow: rgba(37, 42, 49, 0.12) 0px 2px 4px -1px,
    rgba(37, 42, 49, 0.16) 0px 0px 2px -1px;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
