// variables
$color-orange: #ff5e0d;
$color-grey: #2f2c2c;
$font: "DM Sans";
$color-white: #ffffff;

.sidebar {
  background-color: $color-white;
  font-size: 0.8rem;
  font-weight: 500;
  width: inherit;
  .active {
    color: $color-orange;
  }
  ul {
    padding-bottom: 20px;
  }
  .link {
    transition: color 300ms ease-in-out;
    margin: 20px 0;
  }
  .link:hover {
    color: $color-orange;
    cursor: pointer;
  }
  .link.comingSoon {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .link.comingSoon:hover {
    position: relative;
    &::after {
      content: "Coming Soon";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #eee;
      @apply bg-white text-tramangoOrange;
      border-radius: 2px;
      padding: 1px 2px;
      font-size: 0.6rem;
      width: max-content;
    }
  }
  .linkCntr {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    .icon {
      font-size: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 50px;
  }
}
