// variables
$color-orange: #ff5e0d;
$color-grey: #2f2c2c;
$font: "DM Sans";
$color-white: #ffffff;
.icon {
  transform: rotate(0deg);
  transition: transform 0.3s;
  margin-left: 5px;
  display: inline;
}
.nav {
  @apply shadow-sm;
  color: #2f2c2c !important;
  height: 60px;
  font-size: 0.9em;
  font-weight: bold;
  position: fixed;
  background-color: $color-white;
  padding: 0px 50px 0px 20px;
  width: 100%;
  z-index: 20;
  display: flex;
  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .left {
    display: flex;
    align-items: center;
    .burgerToggle {
      margin-right: 20px;
      font-size: 1rem;
      display: none;
    }
    .logo {
      position: relative;
      width: 125px;
      cursor: pointer;
      image {
        width: 100%;
        height: 100%;
      }
    }
  }
  .right {
    .navLinks {
      display: flex;
      align-items: center;
    }
    li {
      margin: 0 1.25rem;
      transition: color 300ms ease-in-out;
      position: relative;
    }

    li:hover {
      cursor: pointer;
      color: $color-orange;
    }

    ul {
      cursor: auto;
    }
    .mobile {
      display: none;
    }
    .signup {
      padding: 5px 17px 5px 17px;
      border: 1px solid $color-orange;
      background-color: $color-orange;
      color: $color-white;
      border-radius: 4px;
      margin-right: 0;
      transition: color 300ms ease-in-out, background-color 300ms ease-in-out;
    }
    .signup:hover {
      background-color: $color-white;
      color: $color-orange;
    }
  }
  @media (max-width: 768px) {
    padding: 10px 20px;
    height: 50px;
    .left {
      .burgerToggle {
        display: block;
      }
      .icons {
        transition: 0.15s color ease-in-out;
      }
      .icons:hover {
        color: $color-orange;
      }
      .logo {
        // max-height: 60px;
      }
    }
    .right {
      .navLinks {
        display: flex;
        align-items: center;
      }
      li {
        margin: 0 1.25rem;
      }
      li:hover {
        color: unset;
      }
      .large {
        display: none;
      }
      .mobile {
        display: flex;
      }
      .navLinks {
        li {
          margin: 0 0 0 20px;
          position: relative;
        }
      }
      .icons {
        font-size: 1.2rem;
        transition: 0.15s color ease-in-out;
      }
      .icons:hover {
        color: $color-orange;
      }
    }

    .dropdown {
      position: absolute;
      top: 30px;
      right: -10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      width: 150px;

      padding: 10px 10px;
      background-color: #ffffff;
      border-radius: 10px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
      z-index: 0;
      transition: all 0.2s ease-in;

      li {
        margin: 5px 0px !important;
      }
      .currency {
        position: relative;
      }
      .language {
        position: relative;
      }
      .currency select,
      .language select {
        font-size: 15px;
        border: 1px solid #ff5e0d;
        padding: 5px 10px;
        border-radius: 6px;
        text-align: center;
        width: 120px;
        transition: background-color 300ms ease-in-out, color 300ms ease-in-out;
      }
      .currency select:hover,
      .language select:hover {
        background-color: #ff5e0d;
        color: #ffffff;
      }
      .signup {
        padding: 0;
        background-color: transparent;
        border: none;
        color: currentColor;
        border-radius: 0;
        margin-right: 0;
      }
      .signup,
      .login,
      .host {
        transition: color 300ms ease-in-out;
        align-self: flex-start;
        width: 100%;
        padding: 5px;
      }
      .signup:hover,
      .login:hover,
      .host:hover {
        background-color: #ffece2;
        color: #2f2c2c;
        border-radius: 6px;
      }
    }

    .mobileCurrency {
      right: -70px;
    }
  }
}

.mobileSideBar {
  @apply shadow-sm;
  z-index: 10;
  background-color: $color-white;
  position: fixed;
  top: 0px;
  bottom: 0;
  left: -100%;
  width: 100px;
  transition: left 500ms;
  overflow-x: hidden;

  @media (max-width: 768px) {
    &.active {
      left: 0%;
    }
    position: fixed;
    top: 0px;
    width: 100px;
    height: calc(100vh);
  }
}

.mobileOverlay {
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 2;
  width: 100%;
  position: fixed;
  height: 100%;
}
