// variables
$color-orange: #ff5e0d;
$color-grey: #2f2c2c;
$font: "DM Sans";
$color-white: #ffffff;

.navSpace {
  height: 60px;
  width: 100%;

  @media (max-width: 768px) {
    height: 50px;
  }
}
