$color-orange: #ff5e0d;

.footer {
  @apply shadow-sm;
  width: 100%;
  padding: 50px;
  background-color: #ffffff;
  @media screen and (max-width: 767px) {
    padding: 20px;
  }
  .logo {
    cursor: pointer;
    // margin-top: -24px;
  }
  .pageLinks {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 30px;
    @media screen and (max-width: 1080px) {
      grid-template-columns: repeat(4, auto);
    }
    @media screen and (max-width: 960px) {
      grid-template-columns: repeat(2, auto);
    }
    @media screen and (max-width: 600px) {
      grid-template-columns: repeat(2, auto);
    }
    .footerSect {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: space-between;
    }
    h3 {
      color: #000;
      font-size: 0.8rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin-bottom: 0.5rem;
    }
    a {
      display: block;
      color: rgba(47, 44, 44, 0.73);
      font-size: 0.9rem;
      line-height: 1.5rem;
      cursor: pointer;
      &:hover {
        transition: color 300ms ease-in-out;
        color: $color-orange;
      }
    }
    .socialIcons {
      display: flex;
      gap: 15px;
      margin-top: 10px;
      .icons {
        color: rgba(47, 44, 44, 0.73);
        font-size: 1.1rem;
        &:hover {
          transition: color 300ms ease-in-out;
          color: $color-orange;
        }
      }
    }
    .atmCards {
      display: flex;
      margin-top: 25px;
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 10px;
      margin-left: -10px;
      img {
        max-width: 50px;
      }
    }
    .socialIcons.small {
      display: none;
    }
  }

  .copyright {
    padding-top: 30px;
    text-align: center;
    font-size: 1rem;
    margin-top: 20px;
    color: rgba(47, 44, 44, 0.73);

    @media screen and (max-width: 600px) {
      text-align: left;
    }
  }
}
