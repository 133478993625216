.currencyDropdown {
  width: 230px;
  background-color: white;
  border-radius: 10px;
  position: absolute;
  z-index: 2000;
  top: 35px;
  right: -100px;
  box-shadow: 0px 4px 20px rgba(67, 67, 67, 0.05);
  padding: 10px;
  .options {
    padding: 5px 10px;
    width: 100%;
    margin: 0 !important;
    color: #000000;
  }
  .options:hover {
    background-color: #ffece2;
    border-radius: 5px;
    color: #000000 !important;
  }
  .options.active {
    color: #ff5e0d !important;
  }
}
